





































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProductCategoryCard extends Vue {
    @Prop() name!: string;
    @Prop() slug!: string;
    @Prop() amount!: number;
    @Prop() image!: string;
}
